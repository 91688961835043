import React, { ReactElement } from "react";
import Image from "next/image";
import style from "./style.module.scss";

export default function SellingBox(): ReactElement {
    return (
        <div className={`${style.bg} py-10`}>
            <div className={`${style.carBg}`}>
                <div
                    className={`${style.center} block md:flex md:items-center md:justify-between`}
                >
                    <div className={`${style.briefWrapper} relative`}>
                        <div
                            className={`${style.title} font-normal mb-2 md:mb-9`}
                        >
                            Exploring{" "}
                            <span
                                className={`${style.highlight} block font-black`}
                            >
                                Insurance?
                            </span>{" "}
                            Options? Look No Further!
                        </div>
                        <p
                            className={`${style.brief} capitalize text-justify pr-5`}
                        >
                            At Insure Your Car, we firmly believe that the path
                            to informed decisions begins with thoroughness and
                            honesty. That's why we've teamed up with a diverse
                            range of trusted insurance providers, ensuring that
                            you have access to every possible option. Our
                            user-friendly comparison engine simplifies the
                            process, allowing you to discover the ideal choice
                            in mere minutes.
                        </p>
                    </div>

                    <div className={style.bannerImage}>
                        <Image
                            src="/insureyourcar/assets/images/car2Opt.png"
                            width="773"
                            height="369"
                            alt="banner"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
